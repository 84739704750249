<script>

import {
  LMap, LMarker, LTileLayer, LIcon,
} from '@vue-leaflet/vue-leaflet';
import { latLng, latLngBounds } from 'leaflet';
import { incidentsComputed } from '@/state/helpers';

export default {
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LIcon,
  },
  data() {
    return {
      zoom: 3,
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      staticAnchor: [16, 37],
      attribution:
        '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
    };
  },
  computed: {
    ...incidentsComputed,
    bound() {
      if (this.getActiveIncidents) {
        const points = [];
        Object.keys(this.getActiveIncidents).forEach((key) => {
          points.push(latLng(
            this.getActiveIncidents[key].coordinates.points.latitude,
            this.getActiveIncidents[key].coordinates.points.longitude,
          ));
        });
        return latLngBounds(points);
      }
      return latLngBounds(38.6841854, -90.3941869);
    },
    center() {
      return this.bound.getCenter();
    },
  },
};
</script>

<template>
  <l-map :center="center" :max-bounds="bound" :zoom="zoom">
    <l-tile-layer :attribution="attribution" :url="url"/>
    <l-marker
      v-for="marker in getActiveIncidents"
      :key="marker.id"
      :lat-lng="{lat: marker.coordinates.points.latitude, lng: marker.coordinates.points.longitude}"
    >
      <l-icon :icon-anchor="staticAnchor">
        <img :src="'/img/incidents/' + marker.map_icon" alt=""/>
      </l-icon>
    </l-marker>
  </l-map>
</template>
